.suggestions {
    border: 1px solid #999;
    border-top-width: 0;
    list-style: none;
    max-height: 161px;
    overflow-y: auto;
    padding: 0.5rem 0.75rem;
    background-color: white;
    width: 100%;
    text-align: left;
    position: absolute;
    z-index: 100;
}

.suggestion-active,
.suggestions li:hover {
    cursor: pointer;
    font-weight: bold;
}

.mainComponentContainer {
    margin-top: 20px;
}